import styled from "styled-components";

export const CustomerDetailWrap = styled.div`
  min-height: 946px;
  padding: 5px 5px 5px 5px;
  .customer-info {
    min-height: 890px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid lightGray;
    .customer-info-form {
      width: 700px;
      margin-left: 100px;
      margin-top: 150px;
      padding: 5px 5px 5px 5px;
      .form-wrap{
        padding: 5px 5px 5px 5px;
        border-radius: 7px;
        border: 1px solid lightGray;
        div{
          margin-left: 30px;
        }
        input{
          width: 150px;
          margin-left: 70px;
        }
        button{
          margin-left: 60px;
        }
      }
      .bottom-button{
        display:flex;
        justify-content: center;
        padding: 10px 10px 10px 10px;
        button{
          margin: 5px 5px 5px 5px;
        }
      }
    }
  }
`;
