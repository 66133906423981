import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Table, Switch, Button, Input } from "antd";
import useAsync from "../../../lib/hooks/useAsync";
import * as APIS from "../../../lib/api/index";
import DataTable from "../../../components/common/DataTable";
import { WholeSaleWrap } from "./index";
import { cloneDeep } from "lodash";
import { numberWithCommas, unComma } from "../../../lib/utils";
import { onOpen } from "../../../modules/reducers/modal";

const WholeSaleQuote = () => {
  const [state, refetch] = useAsync(
    APIS.getWholeSaleQuote,
    [],
    false,
    () => {}
  );
  const [wholeSaleData, setWholeSaleData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const dispatch = useDispatch();
  const { data } = state;

  useEffect(() => {
    setWholeSaleData(
      data?.["wholesalePriceDtoList"]?.map((item) => {
        return {
          ...item,
          isModify: false,
          premium: item?.premium?.toFixed(2) || "-",
          contentPremium: item?.contentPremium?.toFixed(2) || "-",
        };
      }) || []
    );
    setTempData(
      data?.["wholesalePriceDtoList"]?.map((item) => {
        return {
          ...item,
          isModify: false,
          premium: item?.premium?.toFixed(2) || "-",
          contentPremium: item?.contentPremium?.toFixed(2) || "-",
        };
      }) || []
    );
  }, [data]);

  const onClickModify = (index) => {
    let copyData = cloneDeep(wholeSaleData);
    copyData[index] = {
      ...copyData[index],
      isModify: true,
    };
    setWholeSaleData(copyData);
  };

  const onClickCancel = (index) => {
    let copyData = cloneDeep(tempData);
    copyData[index] = {
      ...copyData[index],
      isModify: false,
    };
    setWholeSaleData(copyData);
  };

  const onChangeSwitch = (checked, key, index) => {
    let copyData = cloneDeep(wholeSaleData);
    copyData[index] = {
      ...copyData[index],
      [key]: checked,
    };
    setWholeSaleData(copyData);
  };

  const onClickSave = (index) => {
    // true : 자동
    // false : 수동
    const check = wholeSaleData[index]?.isManual || false;
    let saveData = {};
    if (!check) {
      saveData = {
        ...wholeSaleData[index],
        premium: +(wholeSaleData[index]["premium"] / 100).toFixed(3),
        weight:
          wholeSaleData[index]["weight"] instanceof Number
            ? unComma(wholeSaleData[index]["weight"])
            : unComma(wholeSaleData[index]["weight"]),
        content: +wholeSaleData[index]["content"],
        contentPremium: +(wholeSaleData[index]["contentPremium"] / 100).toFixed(
          3
        ),
        fee:
          wholeSaleData[index]["fee"] instanceof Number
            ? unComma(wholeSaleData[index]["fee"])
            : unComma(wholeSaleData[index]["fee"]),
        standardPrice:
          wholeSaleData[index]["standardPrice"] instanceof Number
            ? unComma(wholeSaleData[index]["standardPrice"])
            : unComma(wholeSaleData[index]["standardPrice"]),
        autoFluctuation:
          wholeSaleData[index]["autoFluctuation"] instanceof Number
            ? unComma(wholeSaleData[index]["autoFluctuation"])
            : unComma(wholeSaleData[index]["autoFluctuation"]),
      };
    } else {
      saveData = {
        id: wholeSaleData[index].id,
        manualPrice:
          wholeSaleData[index].manualPrice instanceof Number
            ? unComma(wholeSaleData[index].manualPrice)
            : unComma(wholeSaleData[index].manualPrice),
      };
    }

    const actionType = !check
      ? () =>
          refetch(
            () => APIS.patchModifyAutoWholeSaleQuote(saveData),
            "수정되었습니다.",
            true
          )
      : () =>
          refetch(
            () => APIS.patchModifyManualWholeSaleQuote(saveData),
            "수정되었습니다.",
            true
          );
    const modal = {
      type: "confirm",
      message: "저장하시겠습니까?",
      action: actionType,
    };
    dispatch(onOpen(modal));
  };

  const onChangeValue = (e, index) => {
    const { value, name } = e.target;
    let copyData = cloneDeep(wholeSaleData);
    let newValue = value;

    if (name === "content" || name === "premium" || name === "contentPremium") {
      const _pattern2 = /^\d*[.]\d{4}$/;
      if (_pattern2.test(newValue)) {
        return false;
      }
    } else {
      newValue = numberWithCommas(newValue.replace(/[^0-9]/g, ""));
    }

    copyData[index] = {
      ...copyData[index],
      [name]: newValue,
    };
    setWholeSaleData(copyData);
  };

  const EditCell = (value, record, index, key = "") => {
    const check = record?.["isModify"] || false;
    const manual = !record?.["isManual"] || false;
    const type =
      key === "content" || key === "premium" || key === "contentPremium"
        ? true
        : false;
    return check && manual && key !== "manualPrice" ? (
      <Input
        value={type ? value : numberWithCommas(value) || ""}
        name={key}
        style={{ width: key === "content" ? "80px" : "120px" }}
        maxLength={key === "contentPremium" || key === "premium" ? 7 : 20}
        onChange={(e) => onChangeValue(e, index)}
      />
    ) : check && !manual && key === "manualPrice" ? (
      <Input
        value={type ? value : numberWithCommas(value) || ""}
        name={key}
        onChange={(e) => onChangeValue(e, index)}
      />
    ) : (
      <span className="right">
        {key === "content" ? value || "-" : numberWithCommas(value || "-")}
      </span>
    );
  };

  const { Column } = Table;

  return (
    <WholeSaleWrap>
      <div className="main-title-wrap" id="dia-list">
        <span className="main-title">도매시세관리</span>
      </div>
      <div className="sub-title">
        <span>시세/매입가 관리 &gt; 도매시세관리</span>
      </div>
      <div className="detail-wrap">
        <div className="detail-info">
          <div className="table-wrap">
            <DataTable data={wholeSaleData} height={800}>
              <Column
                title="분류"
                key="description"
                dataIndex="description"
                width={100}
              />
              <Column
                title="프리미엄률(%)"
                key="premium"
                dataIndex="premium"
                className="right"
                render={(value, record, index) =>
                  EditCell(value, record, index, "premium")
                }
              />
              <Column
                title="중량(g)"
                key="weight"
                dataIndex="weight"
                className="right"
                render={(value, record, index) =>
                  EditCell(value, record, index, "weight")
                }
              />
              <Column
                title="함량"
                key="content"
                dataIndex="content"
                className="right"
                width={100}
                render={(value, record, index) =>
                  EditCell(value, record, index, "content")
                }
              />
              <Column
                title="함량프리미엄률(%)"
                key="contentPremium"
                dataIndex="contentPremium"
                className="right"
                render={(value, record, index) =>
                  EditCell(value, record, index, "contentPremium")
                }
              />
              <Column
                title="가공료(원)"
                key="fee"
                dataIndex="fee"
                className="right"
                render={(value, record, index) =>
                  EditCell(value, record, index, "fee")
                }
              />
              <Column
                title="변동값(±)"
                key="autoFluctuation"
                dataIndex="autoFluctuation"
                className="right"
                render={(value, record, index) =>
                  EditCell(value, record, index, "autoFluctuation")
                }
              />
              <Column
                title="변동기준값"
                key="standardPrice"
                dataIndex="standardPrice"
                className="right"
                render={(value, record, index) =>
                  EditCell(value, record, index, "standardPrice")
                }
              />
              <Column
                title="수동 가격"
                key="manualPrice"
                dataIndex="manualPrice"
                className="right"
                render={(value, record, index) =>
                  EditCell(value, record, index, "manualPrice")
                }
              />
              <Column
                title="수동 / 자동 여부"
                key="isManual"
                dataIndex="isManual"
                className="center"
                width={130}
                render={(props, record, index) => {
                  return (
                    <span>
                      <Switch
                        checked={props}
                        disabled={!record?.["isModify"] || false}
                        onChange={(checked) =>
                          onChangeSwitch(checked, "isManual", index)
                        }
                      />
                    </span>
                  );
                }}
              />
              <Column
                title="비고"
                className="center"
                render={(props, _, index) => {
                  return (
                    <span className="center">
                      {props.isModify ? (
                        <>
                          <Button onClick={() => onClickSave(index)}>
                            저장
                          </Button>
                          &nbsp;
                          <Button onClick={() => onClickCancel(index)}>
                            취소
                          </Button>
                        </>
                      ) : (
                        <Button onClick={() => onClickModify(index)}>
                          수정
                        </Button>
                      )}
                    </span>
                  );
                }}
              />
            </DataTable>
          </div>
        </div>
      </div>
    </WholeSaleWrap>
  );
};

export default WholeSaleQuote;
