import {
  FundOutlined,
  UserOutlined,
  TeamOutlined,
  SettingOutlined,
  BarsOutlined,
  GoldOutlined,
} from "@ant-design/icons";

// 사이드 메뉴관리
export const SideMenu = {
  support: { title: "기본설정 관리", icon: <SettingOutlined />, show: false },
  customer: { title: "회원관리", icon: <TeamOutlined />, show: false },
  quote: { title: "시세/매입가 관리", icon: <FundOutlined />, show: true },
  board: { title: "게시판 관리", icon: <BarsOutlined />, show: false },
  manager: { title: "관리자", icon: <UserOutlined />, show: false },
  product: { title: "제품 관리", icon: <GoldOutlined />, show: false },
};

// 사이드 메뉴의 하위 메뉴관리
export const MenuItem = {
  support: [
    {
      route: "/support/company-info",
      show: true,
      title: "기본정보관리",
      icon: <TeamOutlined />,
      key: "defaultSetting",
    },
    {
      route: "/support/banner-list",
      show: true,
      title: "배너/팝업관리",
      icon: <TeamOutlined />,
      key: "bannerManagement",
    },
    {
      route: "/support/seller-list",
      show: true,
      title: "총판관리",
      icon: <TeamOutlined />,
      key: "sellerManagement",
    },
  ],
  customer: [
    {
      route: "/customer/customer-list",
      show: true,
      title: "회원관리",
      icon: <TeamOutlined />,
      key: "customer-list",
    },
    {
      route: "/customer/customer-inquiry",
      show: true,
      title: "1:1문의 관리",
      icon: <TeamOutlined />,
      key: "customer-inquiry",
    },
    {
      route: "/customer/customer-product-inquiry",
      show: true,
      title: "제작문의 관리",
      icon: <TeamOutlined />,
      key: "customer-product-inquiry",
    },
  ],
  manager: [
    {
      route: "",
      show: true,
      title: "하위 관리자 조회",
      icon: <TeamOutlined />,
      key: "managerList",
    },
    {
      route: "",
      show: true,
      title: "하위 관리자 추가",
      icon: <TeamOutlined />,
      key: "managerAdd",
    },
    {
      route: "",
      show: true,
      title: "권한 관리",
      icon: <TeamOutlined />,
      key: "managerAuthority",
    },
  ],
  product: [
    {
      route: "",
      show: true,
      title: "제품 목록",
      icon: <TeamOutlined />,
      key: "productList",
    },
    {
      route: "",
      show: true,
      title: "제품 추가",
      icon: <TeamOutlined />,
      key: "productAdd",
    },
  ],
  quote: [
    {
      route: "/quote/wholeSale-quote-management",
      show: true,
      title: "도매시세관리",
      icon: <FundOutlined />,
      key: "referenceRate",
    },
    {
      route: "/quote/quoteManagement",
      show: true,
      title: "매입가관리",
      icon: <FundOutlined />,
      key: "purchasePrice",
    },
    {
      route: "/quote/domesticeManagement",
      show: true,
      title: "국내%별 시세관리",
      icon: <FundOutlined />,
      key: "domesticPrice",
    },
    {
      route: "/quote/internationalManagement",
      show: true,
      title: "국제시세관리",
      icon: <FundOutlined />,
      key: "internationalPrice",
    },
    {
      route: "/quote/diamondList",
      show: true,
      title: "다이아몬드시세관리",
      icon: <FundOutlined />,
      key: "diamondPrice",
    },
    {
      route: "/quote/securityList",
      show: true,
      title: "유가증권관리",
      icon: <FundOutlined />,
      key: "securityPrice",
    },
  ],
  board: [
    {
      route: "",
      show: true,
      title: "회원사 공지",
      icon: <TeamOutlined />,
      key: "productAdd1",
    },
    {
      route: "",
      show: true,
      title: "뉴스",
      icon: <TeamOutlined />,
      key: "productAdd2",
    },
    {
      route: "",
      show: true,
      title: "시황정보",
      icon: <BarsOutlined />,
      key: "productAdd3",
    },
  ],
};
