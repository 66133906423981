import React from "react";
import { Route } from "react-router-dom";
import CompanyInfo from "./supportCompanyInfo/CompanyInfo";
import BannerList from "./supportBannerList/BannerList";

const Customer = (props) => {
  return (
    <>
      <Route
        path="/support/company-info"
        render={(routerProps) => <CompanyInfo {...props} {...routerProps} />}
      />
            <Route
        path="/support/banner-list"
        render={(routerProps) => <BannerList {...props} {...routerProps} />}
      />
    </>
  );
};

export default Customer;
