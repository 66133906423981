import React, { useState } from "react";
import { Modal, Button } from "antd";
import { useDispatch } from "react-redux";
import { onClose } from "../../modules/reducers/modal";

const CustomModal = (props) => {
  const dispatch = useDispatch();
  const {
    title = "알림",
    type = "alert",
    components = {},
    message = "완료되었습니다.",
    action = () => {},
  } = props;

  let modal = {};

  const [open, setOpen] = useState(props.status);

  const onClickCloseModal = () => {
    dispatch(onClose());
    setOpen(false);
  };

  const onClickConfirmModal = (e) => {
    //confirm modal 확인을 클릭시 props로 받은 함수를 실행
    //이렇게 사용한 이유는 confirm 의 결과값이 true / false 인지 알 수 있는 방법을 찾지못함......
    //가능하면 추후에 리팩토링 예정
    action();
    dispatch(onClose());
    setOpen(false);
  };

  switch (type) {
    case "alert":
      modal = (
        <Modal
          title={title}
          visible={open}
          width={300}
          height={300}
          onCancel={onClickCloseModal}
          centered
          footer={[<Button onClick={onClickCloseModal}>확인</Button>]}
        >
         <span>{message}</span>
        </Modal>
      );
      break;
    case "confirm":
      modal = (
        <Modal
          title={title}
          visible={open}
          onCancel={onClickCloseModal}
          centered
          footer={[
            <Button name="confirm" onClick={onClickConfirmModal}>
              확인
            </Button>,
            <Button name="cancel" onClick={onClickCloseModal}>
              취소
            </Button>,
          ]}
        >
         <span>{message}</span>
        </Modal>
      );
      break;
    case "custom":
      modal = (
        <Modal
          title={title}
          visible={open}
          onCancel={onClickCloseModal}
          centered
          footer={[<Button>확인</Button>]}
        >
          {components}
        </Modal>
      );
      break;
    default:
      modal = (
        <Modal
          title={title}
          visible={open}
          onCancel={onClickCloseModal}
          centered
          footer={[<Button>확인</Button>]}
        >
          {message}
        </Modal>
      );
      break;
  }

  return modal;
};

export default CustomModal;