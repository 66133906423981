import React from "react";
//import CustomTable from "../../../components/common/CustomTable";
//import TableCell from "../../../components/common/TableCell";
import DropDownList from "../../../components/common/DropDownList";
import { Button, Input } from "antd";
import { CustomerListWrap } from "./index";

const CustomerList = () => {
  // const columns = [
  //   {
  //     title: "번호",
  //     dataIndex: "index",
  //     key: "index",
  //   },
  //   {
  //     title: "아이디",
  //     dataIndex: "id",
  //     key: "name",
  //   },
  //   {
  //     title: "상호",
  //     dataIndex: "age",
  //     key: "age",
  //   },
  //   {
  //     title: "대표자",
  //     dataIndex: "address",
  //     key: "address",
  //   },
  //   {
  //     title: "전화",
  //     dataIndex: "address",
  //     key: "address",
  //   },
  //   {
  //     title: "등급",
  //     dataIndex: "address",
  //     key: "address",
  //   },
  //   {
  //     title: "가입일",
  //     dataIndex: "address",
  //     key: "address",
  //   },
  // ];

  // const data = [
  //   {
  //     key: 1,
  //     name: "준용-1",
  //     age: 21,
  //     address: "경기도",
  //   },
  //   {
  //     key: 2,
  //     name: "준용-2",
  //     age: 22,
  //     address: "강원도",
  //   },
  //   {
  //     key: 3,
  //     name: "준용-3",
  //     age: 23,
  //     address: "서울",
  //   },
  // ];

  const onChangeType = () => {
    console.log("change");
  };

  const dropDownListData = [
    { key: "id", value: "ID" },
    { key: "ceo", value: "대표자" },
    { key: "title", value: "상호" },
    { key: "tel", value: "전화번호" },
    { key: "phoneNumber", value: "휴대폰번호" },
  ];

  return (
    <CustomerListWrap>
      <div className="main-title-wrap">
        <span className="main-title">회원관리</span>
      </div>
      <div className="sub-title">
        <span>회원관리 &gt; 회원관리</span>
      </div>
      <div className="search-area">
        <DropDownList data={dropDownListData} onChange={onChangeType} />{" "}
        <Input type="text" allowClear />
        <Button>검색</Button>
      </div>
      <br />
      <br />
      <div>
        {/* <CustomTable
         //columns={columns}
          data={data}
          name="customerList"
          pagination
        >
          {data.map((_, index) => {
            return (
              <React.Fragment key={index}>
                <TableCell
                  field="name"
                  align="center"
                  data={data}
                  index={index}
                />
                <TableCell
                  field="age"
                  align="center"
                  data={data}
                  index={index}
                />
                <TableCell
                  field="address"
                  align="center"
                  data={data}
                  index={index}
                />
              </React.Fragment>
            );
          })}
        </CustomTable> */}
      </div>
    </CustomerListWrap>
  );
};

export default CustomerList;
