import React, { useState } from "react";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import { Layout, Menu } from "antd";
import {
  TeamOutlined,
  UnorderedListOutlined,
  //HomeOutlined,
} from "@ant-design/icons";
import { MenuItem, SideMenu } from "./MenuItems";
import mainLogo from "../../aseets/img/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { onLogout } from "../../modules/reducers/user";
import { useLocation, useHistory } from "react-router-dom";

const { Sider, Content, Header } = Layout;
const { SubMenu } = Menu;

const MainLayout = (props) => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [selected, setSelected] = useState(location?.state?.menu || "");
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const onSelectMenu = (e) => {
    const { key } = e;
    setSelected(key);
    history.replace({
      state: {
        menu: key,
      },
    });
  };

  const filterMenu = Object.keys(SideMenu);

  let MenuKeyList = [];

  filterMenu.forEach((item) => {
    const check = SideMenu[item].show;
    if (check) {
      MenuKeyList.push(item);
    }
  });

  return (
    <Layout className="main-layout">
      <Layout>
        <Header
          style={{
            display: "flex",
            flexDirection: "row",
            background: "#fff",
            justifyContent: "space-between",
          }}
        >
          <div style={{ color: "#000" }}>
            <Link to="/quote/wholeSale-quote-management">
              <img src={mainLogo} width="250px" height="64px" alt="mainLogo" />
            </Link>
          </div>
          <div
            style={{
              color: "#000",
              justifyContent: "flex-end",
            }}
          >
            {user.isLogin && <span>[관리자] {user.userIp} |&nbsp;&nbsp;</span>}
            {user.isLogin ? (
              <span onClick={() => dispatch(onLogout())}>
                <Link to="/member/login">로그아웃</Link>
              </span>
            ) : (
              <span>
                <Link to="/member/login">로그인</Link>
              </span>
            )}
          </div>
        </Header>
      </Layout>
      <Layout>
        {user.isLogin && (
          <Sider
            className="min-height"
            collapsible
            collapsed={collapsed}
            onCollapse={onCollapse}
          >
            <Menu
              theme="dark"
              defaultSelectedKeys={["home"]}
              mode="inline"
              onSelect={onSelectMenu}
              // eslint-disable-next-line
              defaultSelectedKeys={selected}
            >
              {/* <Menu.Item key="home" icon={<HomeOutlined />}>
                홈
              </Menu.Item> */}
              {MenuKeyList.map((item) => {
                const filerMenuList =
                  MenuItem[item] instanceof Array
                    ? MenuItem[item].filter((prop) => prop.show !== false) || []
                    : MenuItem[item] || {};
                const icon = filerMenuList?.icon || <TeamOutlined />;
                const defaultIcon = <UnorderedListOutlined />;
                const sideTitle = SideMenu?.[item]?.title || "-";
                const sideIcon = SideMenu?.[item]?.icon || defaultIcon;
                return filerMenuList instanceof Array ? (
                  <SubMenu key={item || "-"} title={sideTitle} icon={sideIcon}>
                    {filerMenuList.map((data) => {
                      return (
                        <Menu.Item
                          key={data?.key || "-"}
                          icon={data?.icon || defaultIcon}
                        >
                          <Link to={data.route || "/"}>
                            {data?.title || "-"}
                          </Link>
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                ) : (
                  <Menu.Item key={item || "-"} icon={icon || defaultIcon}>
                    {sideTitle}
                  </Menu.Item>
                );
              })}
            </Menu>
          </Sider>
        )}
        <Layout className="main-height">
          <Content>{props.children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
