import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useAsync from "../../../lib/hooks/useAsync";
import * as APIS from "../../../lib/api/index";
import { DetailWrap } from "./index";
import { Button, Descriptions, Input } from "antd";
import { numberWithCommas } from "../../../lib/utils";
import { onOpen } from "../../../modules/reducers/modal";

const QuoteDetail = (props) => {
  const id = props.location.state.id;
  const [isModify, setModify] = useState(false);
  const [state, refetch] = useAsync(() => APIS.getPurchaseDetail(id), [], true, () => setModify(false));
  const [detailData, setDetailData] = useState({});
  const [tempData, setTempData] = useState({});
  const dispatch = useDispatch();

  const { data } = state;

  useEffect(() => {
    setDetailData(data);
    setTempData(data);
  }, [data]);

  const onClickBack = () => {
    props.history.goBack();
  };

  const onClickModify = () => {
    setModify(true);
  };

  const onClickCancel = () => {
    setDetailData(tempData);
    setModify(false);
  };

  const onClickSave = () => {
    const saveData = {
      ...detailData,
      gold: detailData["gold"],
      silver: detailData["silver"],
      platinum: detailData["platinum"],
      fkGold: detailData["fkGold"],
      ekGold: detailData["ekGold"],
    };
    const modal = {
      type: "confirm",
      message: "저장하시겠습니까?",
      action: () => {
        refetch(
          () => APIS.patchModifyPurchaseDetail(saveData),
          "저장이 완료되었습니다.",
          true
        );
      },
    };
    dispatch(onOpen(modal));
  };

  const onChangeValue = (e) => {
    const { name, value } = e.target;
    const newValue = value.replace(/[^0-9]/g, "");
    setDetailData({
      ...detailData,
      [name]: newValue,
    });
  };

  return (
    <DetailWrap>
      <div className="main-title-wrap" id="dia-list">
        <span className="main-title">시세/매입가 관리</span>
      </div>
      <div className="sub-title">
        <span>
          시세/매입가 관리 &gt;{" "}
          {tempData?.["type"] || "-" === "U" ? "소비자" : "사업자" || "-"}매입가
        </span>
      </div>
      <div className="detail-wrap">
        <div className="detail-info">
          <Descriptions bordered>
            <Descriptions.Item
              label="구분"
              span={2}
              labelStyle={{ textAlign: "center" }}
            >
              <span>
                {(detailData?.["type"] || "") === "U" ? "소비자" : "사업자"}
              </span>
            </Descriptions.Item>
            <Descriptions.Item
              label="날짜"
              span={2}
              labelStyle={{ textAlign: "center" }}
            >
              <span>{detailData?.["date"] || "-"}</span>
            </Descriptions.Item>
            <Descriptions.Item
              label="순금"
              span={2}
              labelStyle={{ textAlign: "center" }}
            >
              {isModify ? (
                <>
                  ₩{" "}
                  <Input
                    name="gold"
                    value={numberWithCommas(detailData?.["gold"] || "")}
                    onChange={onChangeValue}
                  />
                </>
              ) : (
                <span>₩ {numberWithCommas(detailData?.["gold"] || 0)}</span>
              )}
            </Descriptions.Item>
            <Descriptions.Item
              label="18K"
              span={2}
              labelStyle={{ textAlign: "center" }}
            >
              {isModify ? (
                <>
                  ₩{" "}
                  <Input
                    name="ekGold"
                    value={numberWithCommas(detailData?.["ekGold"] || "")}
                    onChange={onChangeValue}
                  />
                </>
              ) : (
                <span>₩ {numberWithCommas(detailData?.["ekGold"] || 0)}</span>
              )}
            </Descriptions.Item>
            <Descriptions.Item
              label="14K"
              span={2}
              labelStyle={{ textAlign: "center" }}
            >
              {isModify ? (
                <>
                  ₩{" "}
                  <Input
                    name="fkGold"
                    value={numberWithCommas(detailData?.["fkGold"] || "")}
                    onChange={onChangeValue}
                  />
                </>
              ) : (
                <span>₩ {numberWithCommas(detailData?.["fkGold"] || 0)}</span>
              )}
            </Descriptions.Item>
            <Descriptions.Item
              label="백금"
              span={2}
              labelStyle={{ textAlign: "center" }}
            >
              {isModify ? (
                <>
                  ₩{" "}
                  <Input
                    name="platinum"
                    value={numberWithCommas(detailData?.["platinum"] || "")}
                    onChange={onChangeValue}
                  />
                </>
              ) : (
                <span>₩ {numberWithCommas(detailData?.["platinum"] || 0)}</span>
              )}
            </Descriptions.Item>
            <Descriptions.Item
              label="은"
              span={2}
              labelStyle={{ textAlign: "center" }}
            >
              {isModify ? (
                <>
                  ₩{" "}
                  <Input
                    name="silver"
                    value={numberWithCommas(detailData?.["silver"] || "")}
                    onChange={onChangeValue}
                  />
                </>
              ) : (
                <span>₩ {numberWithCommas(detailData?.["silver"] || 0)}</span>
              )}
            </Descriptions.Item>
            <Descriptions.Item
              label="등록일"
              span={2}
              labelStyle={{ textAlign: "center" }}
            >
              <span>{detailData?.["regdate"] || "-"}</span>
            </Descriptions.Item>
          </Descriptions>
          <div className="button-wrap">
            {isModify ? (
              <>
                <Button onClick={onClickSave}>저장</Button>&nbsp;
                <Button onClick={onClickCancel}>취소</Button>
              </>
            ) : (
              <>
                <Button onClick={onClickModify}>수정</Button>&nbsp;
                <Button onClick={onClickBack}>목록</Button>
              </>
            )}
          </div>
        </div>
      </div>
    </DetailWrap>
  );
};

export default QuoteDetail;
