import React from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import MainLayout from "./components/common/MainLayout";
import Customer from "./pages/customer/Customer";
import Quote from "./pages/quote/Quote";
import Support from "./pages/support/Support";
import CustomModal from "./components/common/CustomModal";
import Loading from "./components/common/Loading";
import Login from "./pages/member/memberLogin/Login";
import { STORAGE_KEY } from "./lib/consts";
import { useSelector } from "react-redux";
//import ErrorPage from "./components/errorPage";
//import Product from "./components/product/Product";

const PrivateRoute = ({ children, ...rest }) => {
  const hasLocalData = localStorage.getItem(STORAGE_KEY);
  const isLogin = rest.isLogin || hasLocalData;

  return (
    <Route
      {...rest}
      render={() => {
        return isLogin ? children : <Redirect to="/member/login" />;
      }}
    />
  );
};

const App = () => {
  const user = useSelector((state) => state.user);
  const modal = useSelector((state) => state.modal);
  const loading = useSelector((state) => state.loading);
  const isLogin = user.isLogin;

  return (
    <>
      <MainLayout>
        <Switch>
          <Route path="/member/login" exact render={() => <Login />} />
          <PrivateRoute path="/" exact isLogin={isLogin}>
            <Redirect to="/quote/wholeSale-quote-management" />
          </PrivateRoute>
          <PrivateRoute path="/customer" isLogin={isLogin}>
            <Customer />
          </PrivateRoute>
          <PrivateRoute path="/support" isLogin={isLogin}>
            <Support />
          </PrivateRoute>
          <PrivateRoute path="/quote" isLogin={isLogin}>
            <Quote />
          </PrivateRoute>
        </Switch>
      </MainLayout>
      {modal.status && <CustomModal {...modal} />}
      {loading.isLoading && <Loading />}
    </>
  );
};

export default withRouter(App);
