import styled from "styled-components";

export const DomesticWrap = styled.div`
  padding: 5px 5px 5px 5px;
  background-color: #f0f2f5;
  .sub-title {
    display: flex;
    justify-content: flex-end;
    padding: 5px 15px 10px 5px;
  }

  input {
    width: 200px;
  }

  .table-style {
    table {
      border: 1px solid black;
    }
    th,
    td {
      border: 1px solid black;
    }
    .clicked {
      background: red;
    }
    cursor: pointer;
  }

  .detail-wrap {
    display: flex;
    justify-content: center;
    .button-wrap {
      margin: 10px;
    }
    .detail-info {
      min-height: 800px;
      width: 1300px;
      padding: 10px;
      background: #fff;
      border-radius: 10px;
      border: 1px solid lightGray;
      .description {
        padding: 10px 10px 10px 10px;
      }
      .button-wrap {
        display: flex;
        justify-content: center;
        flex-direction: row;
        padding: 10px;
      }
    }

    li,
    ol,
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    .tbl_table1 ul {
      display: flex;
      text-align: center;
    }
    .tbl_bs {
      padding: 10px 10px;
      width: 50%;
      border: 1px solid #dbdada;
      font-weight: 700;
      cursor: pointer;
    }
    .tbl_bs.active {
      background-color: #ffe675;
      border: none;
    }
    .tbl_pro {
      padding: 10px 7px;
      width: 20%;
      border: 1px solid #dbdada;
      border-radius: 5px;
      margin: 15px 5px 10px;
      color: #fff;
      background-color: #dddddd;
      font-weight: 700;
      cursor: pointer;
    }
    .tbl_pro.active {
      background-color: #ff8809;
    }
    .tbl_tit {
      padding: 7px 7px;
      width: 20%;
      margin: 0 auto;

      font-weight: 700;
    }
    .tbl_cot {
      padding: 7px 7px;
      width: 20%;
      margin: 0 auto;
    }
    .tbl_cot_ul {
      border-bottom: 1px solid #dbdada;
    }
    .tbl_header {
      margin-left: 200px;
    }
  }
`;
