import React, { useState, useEffect } from "react";
import { DomesticWrap } from "./index";
import useAsync from "../../../lib/hooks/useAsync";
import * as APIS from "../../../lib/api/index";
import { Button, Input } from "antd";
import { createQueryString, numberWithCommas } from "../../../lib/utils";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import { onOpen } from "../../../modules/reducers/modal";

const DomesticManagement = () => {
  const [currentPrice, setCurrentPrice] = useState({});
  const [domesitData, setDomesticData] = useState();
  const [isModify, setModify] = useState(false);
  const [tempData, setTempData] = useState();
  const [type, setType] = useState("AU");
  const [trade, setTrade] = useState("ASK");
  const queryString = createQueryString({ assetType: type, bidAskType: trade });
  const [state, refetch] = useAsync(
    () => APIS.getDomesticList(queryString),
    [],
    false,
    () => setModify(false)
  );
  const dispatch = useDispatch();

  const { data } = state;

  useEffect(() => {
    setDomesticData(
      data?.["percentPremiumDtoList"].map((item) => {
        return { ...item, premium: item?.premium?.toFixed(2) || "-" };
      }) || []
    );
    setTempData(
      data?.["percentPremiumDtoList"].map((item) => {
        return { ...item, premium: item?.premium?.toFixed(2) || "-" };
      }) || []
    );
    setCurrentPrice({
      usdPrice: data?.usdPrice || 0,
      krwPrice: data?.krwPrice || 0,
      regDate: data?.regdate || "-",
    });
  }, [data]);

  useEffect(() => {
    const query = createQueryString({ assetType: type, bidAskType: trade });
    refetch(() => APIS.getDomesticList(query), "", false);
    // eslint-disable-next-line
    if (isModify) {
      setModify(false);
    }
    // eslint-disable-next-line
  }, [type, trade]);

  const onClickCancel = () => {
    setModify(false);
    setDomesticData(tempData);
  };

  const onClickSave = () => {
    const saveData = {
      percentPremiumDtoList: cloneDeep(domesitData).map((item) => {
        return {
          id: item["id"],
          assetType: item["assetType"],
          bidAskType: item["bidAskType"],
          premium: +(item["premium"] / 100).toFixed(3),
        };
      }),
    };

    const modal = {
      type: "confirm",
      message: "저장하시겠습니까?",
      action: () =>
        refetch(
          () => APIS.patchModifyDomesticPrice(saveData),
          "저장되었습니다.",
          true
        ),
    };

    dispatch(onOpen(modal));
  };

  const onChangeValue = (e, index) => {
    const { name, value } = e.target;
    const copyData = cloneDeep(domesitData);
    let parseData = copyData[index];
    const _pattern2 = /^\d*[.]\d{3}$/;
    if (_pattern2.test(value)) {
      return false;
    }

    parseData = {
      ...parseData,
      [name]: value,
    };

    copyData[index] = parseData;
    setDomesticData(copyData);
  };

  return (
    <DomesticWrap>
      <div className="main-title-wrap" id="dia-list">
        <span className="main-title">시세/매입가 관리</span>
      </div>
      <div className="sub-title">
        <span>시세/매입가 관리 &gt; 국내%별 시세관리</span>
      </div>
      <div className="detail-wrap">
        <div className="detail-info">
          <div id="top_calc">
            <h4 className="mdtitl1">국내%별 계산</h4>
            {isModify ? (
              <>
                <Button onClick={onClickSave}>저장</Button>&nbsp;
                <Button onClick={onClickCancel}>취소</Button>
              </>
            ) : (
              <Button onClick={() => setModify(true)}>수정</Button>
            )}
            <span style={{ marginLeft: 15 }}>
              고시시간 : {currentPrice?.regDate || "-"}
            </span>
            <div className="tbl_table1">
              <ul className="tbl_header">
                <li
                  className={`tbl_pro ${type === "AU" && "active"}`}
                  onClick={() => setType("AU")}
                >
                  금(Au)
                </li>
                <li
                  className={`tbl_pro ${type === "AG" && "active"}`}
                  onClick={() => setType("AG")}
                >
                  은(Ag)
                </li>
                <li
                  className={`tbl_pro ${type === "PT" && "active"}`}
                  onClick={() => setType("PT")}
                >
                  백금(Pt)
                </li>
                <li
                  className={`tbl_pro ${type === "PD" && "active"}`}
                  onClick={() => setType("PD")}
                >
                  팔라듐(Pd)
                </li>
              </ul>
              <ul>
                <li
                  className={`tbl_bs ${trade === "ASK" && "active"}`}
                  onClick={() => setTrade("ASK")}
                >
                  매수
                </li>
                <li
                  className={`tbl_bs ${trade === "BID" && "active"}`}
                  onClick={() => setTrade("BID")}
                >
                  매도
                </li>
              </ul>
              <ul className="tbl_cot_ul">
                <li className="tbl_tit">프리미엄율(%)</li>
                <li className="tbl_tit">국제가</li>
                <li className="tbl_tit">국내가(g)</li>
                <li className="tbl_tit">국내가(3.75g)</li>
              </ul>
              {(domesitData || []).map((item, index) => {
                return (
                  <ul className="tbl_cot_ul" key={`domestic${index}`}>
                    <li className="tbl_cot">
                      {isModify ? (
                        <Input
                          value={item?.premium || ""}
                          name="premium"
                          onChange={(e) => onChangeValue(e, index)}
                        />
                      ) : (
                        item?.premium || "-"
                      )}
                    </li>
                    <li className="tbl_cot">
                      {numberWithCommas(item.globalPrice) || "-"}
                    </li>
                    <li className="tbl_cot">
                      {numberWithCommas(item.domesticPrice) || "-"}
                    </li>
                    <li className="tbl_cot">
                      {numberWithCommas(item.domesticPriceDon) || "-"}
                    </li>
                  </ul>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </DomesticWrap>
  );
};

export default DomesticManagement;
