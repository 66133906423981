import React, { useState } from "react";
import { CompanyInfoWrap } from "./index";
import { Input, Button, Descriptions } from "antd";

const CompanyInfo = () => {
  const [modify, setModify] = useState(false);
  const { TextArea } = Input;

  return (
    <CompanyInfoWrap>
      <div className="main-title-wrap">
        <span className="main-title">기본정보관리</span>
      </div>
      <div className="sub-title">
        <span>기본설정 관리 &gt; 기본정보관리</span>
      </div>
      <div className="company-wrap">
        <div className="company-info">
          <div className="description">
            <Descriptions bordered>
              <Descriptions.Item
                label="타이틀바 문구"
                labelStyle={{ width: 150 }}
                span={3}
              >
                {modify ? (
                  <Input
                    type="text"
                    value="한국금거래소쓰리엠-실시간 금시세,환율 정보 제공"
                  />
                ) : (
                  "한국금거래소쓰리엠-실시간 금시세,환율 정보 제공"
                )}
              </Descriptions.Item>
              <Descriptions.Item label="대표 E-Mail" span={3}>
                {modify ? (
                  <Input type="text" value="chu@exgold.co.kr" />
                ) : (
                  "chu@exgold.co.kr"
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label="이용약관"
                span={3}
                labelStyle={{ height: "300px" }}
              >
                <TextArea
                  rows={12}
                  value={`이용약관
               
        
               제 1장총칙
               
               
               제 1조 (목적)
               
               본 약관은 한국금거래소쓰리엠(이하 "당사")가 제공하는
               한국금거래소쓰리엠서비스를 포함한 모든 서비스(이하 "서비스")의
               이용조건 및 절차, 이용자와 당사의 권리, 의무, 책임사항과 기타
               필요한 사항을 규정함을 목적으로 합니다.
              
               
               제 2조 (약관의 효력과변경)
               
               1. 당사는 귀하가 본 약관 내용에 동의하는 것을 조건으로 귀하에게
               서비스를 제공할 것이며, 귀하가 본 약관의 내용에
               동의하는경우, 당사의 서비스
               
               제공 행위 및 귀하의 서비스 사용 행위에는 본 약관이 우선적으로
               적용될 것입니다.`}
                  disabled={modify ? false : true}
                />
              </Descriptions.Item>
              <Descriptions.Item
                label="개인정보취급방침"
                span={3}
                labelStyle={{ height: "300px" }}
              >
                <TextArea
                  rows={12}
                  value={`                (주)한국금거래소쓰리엠(이하 "회사"는)은 고객님의 개인정보를
                중요시하며, "정보통신망 이용촉진 및 정보보호"에 관한 법률을
                준수하고 있습니다. 회사는 개인정보취급방침을 통하여 고객님께서
                제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며,
                개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
                ■ 수집하는 개인정보 항목 및 수집방법 가. 수집하는 개인정보의
                항목 o 회사는 회원가입, 상담, 서비스 신청 등을 위해 아래와 같은
                개인정보를 수집하고 있습니다. - 회원가입시 :
                이름,생년월일,성별,로그인ID,비밀번호,자택전화번호,휴대전화번호,이메일,사업자등록증
                14세미만 가입자의 경우 법정대리인의 정보 - 서비스 신청시 :
                주소,사업자등록증`}
                  disabled={modify ? false : true}
                />
              </Descriptions.Item>
            </Descriptions>
          </div>

          <div className="bottom-button-wrap">
            {modify ? (
              <>
                <Button>확인</Button>&nbsp;
                <Button
                  onClick={() => {
                    setModify(false);
                  }}
                >
                  취소
                </Button>
              </>
            ) : (
              <Button
                onClick={() => {
                  setModify(true);
                }}
              >
                수정
              </Button>
            )}
          </div>
        </div>
      </div>
    </CompanyInfoWrap>
  );
};

export default CompanyInfo;
