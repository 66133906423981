import styled from "styled-components";

export const CompanyInfoWrap = styled.div`
  min-height: 946px;
  padding: 5px 5px 5px 5px;
  .sub-title {
    display: flex;
    justify-content: flex-end;
    padding: 5px 15px 10px 5px;
  }
  .company-wrap {
    display: flex;
    justify-content: center;
    .company-info {
      min-height: 850px;
      width: 1000px;
      background: #fff;
      border-radius: 10px;
      border: 1px solid lightGray;
      .description {
        padding: 10px 10px 10px 10px;
      }
      .bottom-button-wrap {
        display: flex;
        justify-content: center;
        padding: 20px;
      }
    }
  }
`;
