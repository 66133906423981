import styled from "styled-components";

export const MemberLoginWrap = styled.div`
  display: flex;
  height: 952px;
  padding: 5px 5px 5px 5px;
  justify-content: center;
  align-items: center;
  .login-wrap {
    width: 500px;
    height: 400px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 5px lightgray;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .login-logo {
      padding: 40px 5px 5px 5px;
    }
    .login-info {
      margin-bottom: 50px;
      .bottom-button {
        display: flex;
        height: 54px;
        align-items: flex-end;
        button {
          width: 262px;
          height: 32px;
        }
      }
    }
  }
`;
