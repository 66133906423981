import styled from "styled-components";

export const DetailWrap = styled.div`
  padding: 5px 5px 5px 5px;
  .sub-title {
    display: flex;
    justify-content: flex-end;
    padding: 5px 15px 10px 5px;
  }

  input {
    width: 200px;
  }

  .detail-wrap {
    display: flex;
    justify-content: center;
    .button-wrap {
      margin: 10px;
    }
    .detail-info {
      min-height: 800px;
      width: 1300px;
      padding: 10px;
      background: #fff;
      border-radius: 10px;
      border: 1px solid lightGray;
      .description {
        padding: 10px 10px 10px 10px;
      }
      .button-wrap {
        display: flex;
        justify-content: center;
        flex-direction: row;
        padding: 10px;
      }
    }
  }
`;