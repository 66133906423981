import React from "react";
import { Route } from "react-router-dom";
import CustomerList from "./customerList/CustomerList";
import CustomerInquiryList from "./customerInquiry/CustomerInquiryList";
import CustomerDetail from "./customerDetail/CustomerDetail";
import CustomerProductInquiry from "./customerProductInquiry/CustomerProductInquiry";

const Customer = (props) => {
  return (
    <>
      <Route
        path="/customer/customer-list"
        render={(routerProps) => <CustomerList {...props} {...routerProps} />}
      />
      <Route
        path="/customer/customer-inquiry"
        render={(routerProps) => <CustomerInquiryList {...props} {...routerProps} />}
      />
      <Route
        path="/customer/customer-product-inquiry"
        render={(routerProps) => <CustomerProductInquiry {...props} {...routerProps} />}
      />
      <Route
        path="/customer/customer-detail"
        render={(routerProps) => <CustomerDetail {...props} {...routerProps} />}
      />
    </>
  );
};

export default Customer;
