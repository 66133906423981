import styled from "styled-components";

export const DiamondListWrap = styled.div`
  max-height: 915px;
  padding: 5px 5px 5px 5px;
  .sub-title {
    display: flex;
    justify-content: flex-end;
    padding: 5px 15px 10px 5px;
  }
  .button-wrap {
    margin-bottom: 10px;
  }
`;
