import styled from "styled-components";

export const CustomerInquiryWrap = styled.div`
  min-height: 946px;
  padding: 5px 5px 5px 5px;
  .sub-title{
    display: flex;
    justify-content: flex-end;
    padding: 5px 15px 10px 5px;
  }
  .search-area {
    width: 500px;
    padding: 0px 5px 5px 5px;
    position: absolute;
    right: 0px;
    .ant-input-affix-wrapper {
      width: 200px;
    }

    input[type="text"] {
      margin-left: 10px;
      width: 200px;
    }
    button {
      margin-left: 5px;
      width: 70px;
    }
  }
`;