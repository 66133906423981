import React from "react";
import { Route } from "react-router-dom";
import DiamondList from "./diamontList/DiamondList";
import DomesticManagement from "./domesticManagement/DomesticManagement";
import QuoteDetail from "./quoteDetail/QuoteDetail";
import QuoteManagement from "./quoteManagement/QuoteManagement";
import SecurityList from "./securityList/SecurityList";
import WholeSaleQuote from "./wholesaleQuote/WholeSaleQuote";
import InternationalManagement from "./internationalManagement/InternationalManagement";
const Quote = () => {
  return (
    <>
      <Route
        path="/quote/diamondList"
        render={(routerProps) => <DiamondList {...routerProps} />}
      />
      <Route
        path="/quote/wholeSale-quote-management"
        render={(routerProps) => <WholeSaleQuote {...routerProps} />}
      />
      <Route
        path="/quote/internationalManagement"
        render={(routerProps) => <InternationalManagement {...routerProps} />}
      />
      <Route
        path="/quote/domesticeManagement"
        render={(routerProps) => <DomesticManagement {...routerProps} />}
      />
      <Route
        path="/quote/securityList"
        render={(routerProps) => <SecurityList {...routerProps} />}
      />
      <Route
        path="/quote/quoteManagement"
        render={(routerProps) => <QuoteManagement {...routerProps} />}
      />
      <Route
        path="/quote/quoteDetail"
        render={(routerProps) => <QuoteDetail {...routerProps} />}
      />
    </>
  );
};

export default Quote;
