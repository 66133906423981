import { combineReducers } from "redux";
import modal from "./modal";
import loading from "./loading";
import user from "./user";
import page from "./page";

const rootReducer = combineReducers({
  modal,
  loading,
  user,
  page
});

export default rootReducer;