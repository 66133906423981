import styled from "styled-components";

export const QuoteManagementWrap = styled.div`
  padding: 5px 5px 5px 5px;
  background: #f0f2f5;
  .sub-title {
    display: flex;
    justify-content: flex-end;
    padding: 5px 15px 10px 5px;
  }
  .search-wrap {
    display: flex;
    flex-direaction: rows;
    padding: 10px;
    input {
      width: 200px;
    }
  }

  .table-wrap {
    padding: 10px;
  }
`;
