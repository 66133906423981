import moment from "moment";

export function numberWithCommas(x) {
  if (x) {
    let onlyNumber = x.toString().replace(/,/gi, "");
    let parts = onlyNumber.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return parts.join(".");
  } else if (x === "") {
    return "";
  } else if (x === 0) {
    return "0";
  } else {
    return "0";
  }
}

export function unComma(value = 0) {
  const check = typeof value === "string" ? true : false;
  const n = check ? parseInt(value.replace(/,/g, "")) : value;
  return n;
}

export function createQueryString(value) {
  return `?${Object.entries(value)
    .map((e) => e.join("="))
    .join("&")}`;
}

export function calcDate(some, dateUnit, to = new Date(), type = "subtract") {
  if (!some) {
    return "";
  } else {
    if (type === "add") {
      return moment(to).add(some, dateUnit).format("YYYY-MM-DD");
    } else {
      return moment(to).subtract(some, dateUnit).format("YYYY-MM-DD");
    }
  }
}

export const formatTime = (date, format) => {
  if (date) {
    return moment(date).format(format);
  } else {
    return "";
  }
};
