import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Button, Table, Tabs } from "antd";
import * as APIS from "../../../lib/api/index";
import DataTable, {
  numberWithCommasCell,
} from "../../../components/common/DataTable";
import useAsync from "../../../lib/hooks/useAsync";
import { InternationalWrap } from "./index";
import CustomDatePicker from "../../../components/common/CustomDatePicker";
import { calcDate, createQueryString, formatTime } from "../../../lib/utils";
import moment from "moment";
import { dateFormat } from "../../../lib/consts";
import { initPage } from "../../../modules/reducers/page";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";

const { Column } = Table;
const InternationalManagement = () => {
  const didMount = useRef(false);
  const dispatch = useDispatch();
  const [list, setDataList] = useState([]);
  const [tab, setTab] = useState("AU");
  const pageData = useSelector((state) => state.page);
  const initDate = {
    from: moment(new Date(calcDate(1, "month"))).format(dateFormat),
    to: moment(new Date()).format(dateFormat),
  };

  const initQueryString = createQueryString({
    type: tab,
    ...initDate,
    size: pageData.size,
    page: 0,
  });
  const [state, refetch] = useAsync(
    () => APIS.getInternationalList(initQueryString),
    [],
    false
  );
  const [date, setDate] = useState(initDate);
  const { data } = state;

  const fetchList = () => {
    const query = {
      type: tab,
      from: moment(date.from).format(dateFormat),
      to: moment(date.to).format(dateFormat),
      page: pageData.number,
      size: pageData.size,
    };

    const queryString = createQueryString(query);
    refetch(() => APIS.getInternationalList(queryString), [], false);
  };

  useEffect(() => {
    setDataList(data?.["content"] || []);
  }, [data]);

  useEffect(() => {
    if (didMount.current) {
      fetchList();
    } else {
      didMount.current = true;
    }
    // eslint-disable-next-line
  }, [pageData.size, pageData.number]);

  useEffect(() => {
    return () => {
      dispatch(initPage());
    };
    // eslint-disable-next-line
  }, []);

  const onChnageDate = (date) => {
    const from = date[0];
    const to = date[1];
    setDate({
      from: from,
      to: to,
    });
  };

  // 검색
  const onClickSearch = () => {
    const query = {
      type: tab,
      from: moment(date.from).format(dateFormat),
      to: moment(date.to).format(dateFormat),
      size: pageData.size,
      page: pageData.number,
    };
    const queryString = createQueryString(query);
    refetch(() => APIS.getInternationalList(queryString), "", false);
  };
  const onClickExcel = () => {
    const query = {
      type: tab,
      from: moment(date.from).format(dateFormat),
      to: moment(date.to).format(dateFormat),
    };
    const queryString = createQueryString(query);
    APIS.getInternationalListExcel(queryString)
      .then((response) => {
        const blob = new Blob([response.data]);
        saveAs(
          blob,
          `국제시세_${formatTime(new Date(), "YYYYMMDDHHmmSS")}.xlsx`
        );
      })
      .catch((err) => alert(err));
  };

  const onChangeTab = (tab) => {
    setDate(initDate);
    const query = {
      type: tab,
      from: moment(date.from).format(dateFormat),
      to: moment(date.to).format(dateFormat),
    };
    const queryString = createQueryString(query);
    refetch(() => APIS.getInternationalList(queryString), "", false);
    setTab(tab);
  };

  const { TabPane } = Tabs;

  const TableComponent = () => {
    return (
      <div className="table-wrap">
        <div style={{ fontWeight: "bold" }}>※ 09시 10분 기준</div>
        <DataTable data={list} pagination height={650}>
          <Column title="날짜" key="date" dataIndex="date" />
          <Column
            title="Bid"
            key="bid"
            dataIndex="bid"
            render={numberWithCommasCell}
          />
          <Column
            title="Ask"
            key="ask"
            dataIndex="ask"
            render={numberWithCommasCell}
          />
          <Column
            title="국제가(USD/T.oz)"
            key="internationalPrice"
            dataIndex="internationalPrice"
            render={numberWithCommasCell}
          />
          <Column
            title="환율(₩/USD)"
            key="exchangeRate"
            dataIndex="exchangeRate"
            render={numberWithCommasCell}
          />
          <Column
            title="국내기준가(₩/g)"
            key="domesticPrice"
            dataIndex="domesticPrice"
            render={numberWithCommasCell}
          />
          <Column
            title="국내기준가(₩/3.75g)"
            key="domesticPriceDon"
            dataIndex="domesticPriceDon"
            render={numberWithCommasCell}
          />
        </DataTable>
      </div>
    );
  };
  return (
    <InternationalWrap>
      <div className="main-title-wrap" id="dia-list">
        <span className="main-title">시세/매입가 관리</span>
      </div>
      <div className="sub-title">
        <span>시세/매입가 관리 &gt; 국제시세관리</span>
      </div>
      <div className="search-wrap">
        <div>
          <CustomDatePicker type="range" onChange={onChnageDate} date={date} />
          &nbsp;<Button onClick={onClickSearch}>검색</Button>
        </div>
        <div>
          <Button onClick={onClickExcel}>Excel</Button>&nbsp;
        </div>
      </div>
      <Tabs
        defaultActiveKey={tab}
        onChange={onChangeTab}
        className="table-wrap"
        style={{ marginTop: "-20px" }}
      >
        <TabPane tab="골드" key="AU" />
        <TabPane tab="실버" key="AG" />
        <TabPane tab="플래티넘" key="PT" />
        <TabPane tab="팔라듐" key="PD" />
      </Tabs>
      <div style={{ marginTop: "-25px" }}>
        <TableComponent />
      </div>
    </InternationalWrap>
  );
};

export default InternationalManagement;
