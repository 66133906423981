import React from "react";
//import CustomTable from "../../../components/common/CustomTable";
import DropDownList from "../../../components/common/DropDownList";
import { Button, Input } from "antd";
import { CustomerInquiryWrap } from "./index";

const CustomerInquiryList = () => {

  const onChangeType = () => {
    console.log("change");
  };

  const dropDownListData = [
    { key: "id", value: "ID" },
    { key: "ceo", value: "대표자" },
    { key: "title", value: "상호" },
    { key: "tel", value: "전화번호" },
    { key: "phoneNumber", value: "휴대폰번호" },
  ];

  return (
    <CustomerInquiryWrap>
      <div className="main-title-wrap">
        <span className="main-title">1:1문의관리</span>
      </div>
      <div className="sub-title">
        <span>회원관리 &gt; 1:1문의관리</span>
      </div>
      <div className="search-area">
        <DropDownList data={dropDownListData} onChange={onChangeType} />{" "}
        <Input type="text" allowClear />
        <Button>검색</Button>
      </div>
      <br />
      <br />
      <div>
      </div>
    </CustomerInquiryWrap>
  );
};

export default CustomerInquiryList;
