import styled from "styled-components";

export const WholeSaleWrap = styled.div`
  padding: 5px 5px 5px 5px;
  background: #f0f2f5;
  .sub-title {
    display: flex;
    justify-content: flex-end;
    padding: 5px 15px 10px 5px;
  }

  input {
    width: 150px;
  }

  .detail-wrap {
    display: flex;
    justify-content: center;
    
    .button-wrap {
      margin: 10px;
    }
    .detail-info {
      min-height: 800px;
      min-width: 1540px;
      max-width: 1600px;
      padding: 5px;
      background: #fff;
      border-radius: 10px;
      border: 1px solid lightGray;
      .description {
        padding: 10px 10px 10px 10px;
      }
      .table-wrap {
        display: flex;
        justify-content: center;
      }
      input {
        width: 120px;
      }
      .button-wrap {
        display: flex;
        justify-content: center;
        flex-direction: row;
        padding: 10px;
      }
    }
  }
`;
