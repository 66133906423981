import axios from "axios";
import { onOpen } from "../../modules/reducers/modal";
import { onLogout } from "../../modules/reducers/user";
//import { useDispatch } from 'react-redux';
//import { onOpen } from "../../modules/reducers/modal";

//const dispatch = useDispatch();

// set api base url
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Cache-Control"] = "no-cache";
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

// 응답 인터셉터 추가
axios.interceptors.response.use(
  function (response) {
    // 응답 데이터를 가공
    // ...
    return response;
  },
  function (error) {
    // 오류 응답을 처리
    // ...
    const { store } = require("../../modules/store");
    const status = error?.response?.status;
    console.log("errerr", error);
    if (
      status === 403 ||
      typeof error.response === "undefined" ||
      status === 401
    ) {
      store.dispatch(
        onOpen({ message: "인증 토큰이 만료되었습니다.\n다시 로그인해주세요." })
      );
      store.dispatch(onLogout());
    } else if (status !== 422) {
      // 422 제외 에러처리
      store.dispatch(onOpen({ message: "알 수 없는 오류가 발생했습니다!!!" }));
    }

    return Promise.reject(error);
  }
);

// 로그인
export const postLogin = async (data) => {
  return axios.post(`/api/v1/manager/auth/login`, data);
};

/**
 * 다이아몬드 관리
 */
// 다이아몬드 목록 조회
export const getDiamondList = async () => {
  return axios.get(`/api/v1/manager/quote/diamond`);
};

// 다이아몬드 목록 추가
export const postAddDiamondItem = (data) => {
  return axios.post(`/api/v1/manager/quote/diamond`, data);
};

// 다이아몬드 목록 삭제
export const deleteDiamondItem = async (data) => {
  return axios.delete(`/api/v1/manager/quote/diamond`, {
    data: { ...data },
  });
};

// 다이아몬드 목록 수정
export const patchModifyDiamondItem = async (data) => {
  return axios.patch(`/api/v1/manager/quote/diamond`, data);
};

// 다이아몬드 목록 포지션 변경
export const patchChangePositionDiamondList = async (data) => {
  return axios.patch(`/api/v1/manager/quote/diamond/position`, data);
};

/**
 * 유가증권 관리
 */

// 유가증권 목록 가져오기
export const getSecurityList = async () => {
  return axios.get(`/api/v1/manager/quote/security`);
};

// 유가증권 수정
export const patchModifySecurityList = async (data) => {
  return axios.patch(`/api/v1/manager/quote/security`, data);
};

/**
 * 소비자/사업자 매입가관리
 */

// 사업자/소비자 매입가 내역 조회
export const getPurchaseList = async (data = "") => {
  return axios.get(`/api/v1/manager/quote/purchase${data}`);
};

// 사업자/소비자 내역 상세
export const getPurchaseDetail = async (data) => {
  return axios.get(`/api/v1/manager/quote/purchase/${data}`);
};

// 사업자/소비자 내역 추가
export const postAddPurchaseList = async (data) => {
  return axios.post(`/api/v1/manager/quote/purchase`, data);
};

// 사업자/소비자 상세 수정
export const patchModifyPurchaseDetail = async (data) => {
  return axios.patch(`/api/v1/manager/quote/purchase`, data);
};

// 사업자/소비자 상세 삭제
export const deletePurchaseDetail = async (data) => {
  return axios.delete(`/api/v1/manager/quote/purchase/${data}`);
};
/**
 * 도매시세관리
 */

// 도메 시세 조회
export const getWholeSaleQuote = async () => {
  return axios.get(`api/v1/manager/quote/wholesale`);
};

// 도매 시세 수정(자동)
export const patchModifyAutoWholeSaleQuote = async (data) => {
  return axios.patch(`/api/v1/manager/quote/wholesale/auto`, data);
};

// 도매 시세 수정(수동)
export const patchModifyManualWholeSaleQuote = async (data) => {
  return axios.patch(`/api/v1/manager/quote/wholesale/manual`, data);
};

/**
 * 국내%별 시세관리
 */

// 국내시세조회(매수,매도)
export const getDomesticList = async (data = "") => {
  return axios.get(`/api/v1/manager/quote/percent${data}`);
};

// 국내시세 수정
export const patchModifyDomesticPrice = async (data) => {
  return axios.patch(`/api/v1/manager/quote/percent`, data);
};

/**
 * 국제시세관리
 */

// 국제시세 조회
export const getInternationalList = async (data = "") => {
  return axios.get(`/api/v1/manager/international${data}`);
};

// 국제시세 엑셀 다운로드
export const getInternationalListExcel = async (data = "") => {
  return axios.get(`/api/v1/manager/international/excel${data}`, {
    responseType: "blob",
  });
};
