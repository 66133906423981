const SET_PAGE = "page/SET_PAGE";
const INIT_PAGE = "page/INIT_PAGE";

const INIT_STATE = {
  totalElements: 0,
  number: 0,
  size: 30,
};

export default function page(state = INIT_STATE, action) {
  switch (action.type) {
    case "page/SET_PAGE":
      return { ...state,...action.payload };
    case "page/INIT_PAGE":
      return { ...action.payload };
    default:
      return { ...state };
  }
}

export const setPage = (payload) => {
  return { type: SET_PAGE, payload: payload };
};

export const initPage = () => {
  return { type: INIT_PAGE, payload: INIT_STATE };
};
