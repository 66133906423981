import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Input, Table, Tabs } from "antd";
import * as APIS from "../../../lib/api/index";
import DataTable, {
  numberWithCommasCell,
} from "../../../components/common/DataTable";
import useAsync from "../../../lib/hooks/useAsync";
import { QuoteManagementWrap } from "./index";
import CustomDatePicker from "../../../components/common/CustomDatePicker";
import {
  calcDate,
  createQueryString,
  numberWithCommas,
} from "../../../lib/utils";
import moment from "moment";
import { dateFormat } from "../../../lib/consts";
import { initPage } from "../../../modules/reducers/page";
import { useSelector } from "react-redux";
import { onOpen } from "../../../modules/reducers/modal";
import { unComma } from "../../../lib/utils";
import { cloneDeep } from "lodash";

const { Column } = Table;
const QuoteManagement = () => {
  const dispatch = useDispatch();
  const [list, setDataList] = useState([]);
  const [tab, setTab] = useState("U");
  const [addData, setAddData] = useState({
    type: tab,
    gold: "",
    ekGold: "",
    fkGold: "",
    platinum: "",
    silver: "",
    date: moment(new Date()).format(dateFormat),
  });
  const [isModify, setModify] = useState(false);
  const pageData = useSelector((state) => state.page);
  const initDate = {
    from: moment(new Date(calcDate(1, "month"))).format(dateFormat),
    to: moment(new Date()).format(dateFormat),
  };

  const initQueryString = createQueryString({
    type: tab,
    ...initDate,
    size: pageData.size,
    page: 0,
  });
  const [state, refetch] = useAsync(
    () => APIS.getPurchaseList(initQueryString),
    [],
    true,
    () => APIS.getPurchaseList(initQueryString)
  );
  const [date, setDate] = useState(initDate);
  const { data } = state;

  useEffect(() => {
    setDataList(data?.["content"] || []);
  }, [data]);

  useEffect(() => {
    fetchList();
    // eslint-disable-next-line
  }, [pageData.size, pageData.number]);

  useEffect(() => {
    return () => {
      dispatch(initPage());
    };
    // eslint-disable-next-line
  }, []);

  const fetchList = () => {
    const query = {
      type: tab,
      from: moment(date.from).format(dateFormat),
      to: moment(date.to).format(dateFormat),
      page: pageData.number,
      size: pageData.size,
    };

    const queryString = createQueryString(query);
    refetch(() => APIS.getPurchaseList(queryString), [], false);
  };

  const onChnageDate = (date) => {
    const from = date[0];
    const to = date[1];
    setDate({
      from: from,
      to: to,
    });
  };

  // 검색
  const onClickSearch = () => {
    const query = {
      type: tab,
      from: moment(date.from).format(dateFormat),
      to: moment(date.to).format(dateFormat),
      size: pageData.size,
      page: pageData.number,
    };
    const queryString = createQueryString(query);
    refetch(() => APIS.getPurchaseList(queryString), "", false);
  };

  const onChangeTab = (tab) => {
    setDate(initDate);
    const query = {
      type: tab,
      from: moment(date.from).format(dateFormat),
      to: moment(date.to).format(dateFormat),
    };
    const queryString = createQueryString(query);
    refetch(() => APIS.getPurchaseList(queryString), "", false);
    setTab(tab);
  };

  const onClickSave = () => {
    let copyData = cloneDeep(addData);
    copyData = {
      ...copyData,
      type: tab,
      gold:
        copyData["gold"] instanceof Number
          ? +copyData["gold"]
          : unComma(copyData["gold"]),
      ekGold:
        copyData["ekGold"] instanceof Number
          ? +copyData["ekGold"]
          : unComma(copyData["ekGold"]),
      fkGold:
        copyData["fkGold"] instanceof Number
          ? +copyData["fkGold"]
          : unComma(copyData["fkGold"]),
      platinum:
        copyData["platinum"] instanceof Number
          ? +copyData["platinum"]
          : unComma(copyData["platinum"]),
      silver:
        copyData["silver"] instanceof Number
          ? +copyData["silver"]
          : unComma(copyData["silver"]),
    };
    const modal = {
      type: "confirm",
      message: "추가하시겠습니까?",
      action: () =>
        refetch(
          () => APIS.postAddPurchaseList(copyData),
          "추가되었습니다.",
          true
        ),
    };
    dispatch(onOpen(modal));
  };

  const onChangeAddValue = (e) => {
    const { name, value } = e.target;
    const newValue = numberWithCommas(value.replace(/[^0-9]/g, ""));
    setAddData({
      ...addData,
      [name]: newValue,
    });
  };

  const onClickAdd = () => {
    setModify(true);
  };

  const onClickCancel = () => {
    setAddData({
      type: tab,
      gold: "",
      ekGold: "",
      fkGold: "",
      platinum: "",
      silver: "",
    });
    setModify(false);
  };

  const { TabPane } = Tabs;

  const TableComponent = () => {
    return (
      <div className="table-wrap">
        <DataTable
          data={list}
          detail={"/quote/QuoteDetail"}
          pagination
          rowSelection
          height={660}
        >
          <Column title="번호" key="id" dataIndex="id" />
          <Column
            title="구분"
            key="type"
            dataIndex="type"
            render={(value) => {
              return (
                <span className="center">
                  {value === "U" ? "소비자" : "사업자"}
                </span>
              );
            }}
          />
          <Column
            title="날짜"
            key="date"
            dataIndex="date"
            render={(value) => {
              return <span style={{ textAlign: "center" }}>{value}</span>;
            }}
          />
          <Column
            title="순금"
            key="gold"
            dataIndex="gold"
            render={numberWithCommasCell}
          />
          <Column
            title="18K"
            key="ekgold"
            dataIndex="ekGold"
            render={numberWithCommasCell}
          />
          <Column
            title="14K"
            key="fkgold"
            dataIndex="fkGold"
            render={numberWithCommasCell}
          />
          <Column
            title="백금"
            key="platinum"
            dataIndex="platinum"
            render={numberWithCommasCell}
          />
          <Column
            title="은"
            key="silver"
            dataIndex="silver"
            render={numberWithCommasCell}
          />
        </DataTable>
      </div>
    );
  };

  return (
    <QuoteManagementWrap>
      <div className="main-title-wrap" id="dia-list">
        <span className="main-title">시세/매입가 관리</span>
      </div>
      <div className="sub-title">
        <span>시세/매입가 관리 &gt; 소비자/사업자 매입가관리</span>
      </div>
      <div className="search-wrap">
        <CustomDatePicker type="range" onChange={onChnageDate} date={date} />
        &nbsp;<Button onClick={onClickSearch}>검색</Button>&nbsp;
        {isModify ? (
          <>
            <Button onClick={onClickSave}>저장</Button>&nbsp;
            <Button onClick={onClickCancel}>취소</Button>
          </>
        ) : (
          <Button onClick={onClickAdd}>추가</Button>
        )}
      </div>
      {isModify && (
        <div style={{ height: "100px", marginBottom: "10px" }}>
          {AddForm(addData, onChangeAddValue, tab)}
        </div>
      )}
      <Tabs
        defaultActiveKey={tab}
        onChange={onChangeTab}
        className="table-wrap"
        style={{ marginTop: "-20px" }}
      >
        <TabPane
          tab="소비자"
          key="U"
          disabled={isModify}
          style={{ marginTop: "-15px" }}
        >
          <TableComponent />
        </TabPane>
        <TabPane
          tab="사업자"
          key="C"
          disabled={isModify}
          style={{ marginTop: "-15px" }}
        >
          <TableComponent />
        </TabPane>
      </Tabs>
    </QuoteManagementWrap>
  );
};

export default QuoteManagement;

const AddForm = (addData, onChangeAddValue, tab) => {
  return (
    <DataTable data={[addData]} height={80}>
      <Column
        title="분류"
        render={() => {
          return <span>{tab === "U" ? "소비자" : "사업자"}</span>;
        }}
      />
      <Column
        title="금"
        key="gold"
        dataIndex="gold"
        render={() => {
          return (
            <Input
              name="gold"
              value={numberWithCommas(addData.gold)}
              onChange={onChangeAddValue}
            />
          );
        }}
      />
      <Column
        title="18K"
        key="ekGold"
        dataIndex="ekGold"
        render={() => {
          return (
            <Input
              name="ekGold"
              value={numberWithCommas(addData.ekGold)}
              onChange={onChangeAddValue}
            />
          );
        }}
      />
      <Column
        title="14K"
        key="fkGold"
        dataIndex="fkGold"
        render={() => {
          return (
            <Input
              name="fkGold"
              value={numberWithCommas(addData.fkGold)}
              onChange={onChangeAddValue}
            />
          );
        }}
      />
      <Column
        title="백금"
        key="platinum"
        dataIndex="platinum"
        render={() => {
          return (
            <Input
              name="platinum"
              value={numberWithCommas(addData.platinum)}
              onChange={onChangeAddValue}
            />
          );
        }}
      />
      <Column
        title="은"
        key="silver"
        dataIndex="silver"
        render={() => {
          return (
            <Input
              name="silver"
              value={numberWithCommas(addData.silver)}
              onChange={onChangeAddValue}
            />
          );
        }}
      />
    </DataTable>
  );
};
