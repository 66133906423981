import React from "react";
//import CustomTable from "../../../components/common/CustomTable";
import DropDownList from "../../../components/common/DropDownList";
import { Button, Input, Tabs } from "antd";
import { BannerListWrap } from "./index";

const BannerList = () => {
  const { TabPane } = Tabs;
  const columns = [
    {
      title: "구분",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "이미지",
      dataIndex: "image",
      key: "image",
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
    },
    {
      title: "등록일",
      dataIndex: "regdate",
      key: "regdate",
    },
  ];

  const data = [
    {
      key: 1,
      type: "메인1",
      image: "default",
      link: "https://exgold.co.kr",
      regdate: "2021.11.09",
    },
    {
      key: 2,
      type: "메인2",
      image: "default",
      link: "https://exgold.co.kr",
      regdate: "2021.11.09",
    },
    {
      key: 3,
      type: "메인3",
      image: "default",
      link: "https://exgold.co.kr",
      regdate: "2021.11.09",
    },
    {
      key: 4,
      type: "메인4",
      image: "default",
      link: "https://exgold.co.kr",
      regdate: "2021.11.09",
    },
    {
      key: 5,
      type: "메인5",
      image: "default",
      link: "https://exgold.co.kr",
      regdate: "2021.11.09",
    },
  ];

  const onChangeType = () => {
    console.log("change");
  };

  const dropDownListData = [
    { key: "id", value: "ID" },
    { key: "ceo", value: "대표자" },
    { key: "title", value: "상호" },
    { key: "tel", value: "전화번호" },
    { key: "phoneNumber", value: "휴대폰번호" },
  ];

  return (
    <BannerListWrap>
      <div className="main-title-wrap">
        <span className="main-title">배너/팝업관리</span>
      </div>
      <div className="sub-title">
        <span>회원관리 &gt; 배너/팝업관리</span>
      </div>
      <div className="search-area">
        <DropDownList data={dropDownListData} onChange={onChangeType} />&nbsp;
        <Input type="text" allowClear />
        <Button>검색</Button>
      </div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="메인배너" key="1">
          <BannerContent data={data} columns={columns} />
        </TabPane>
        <TabPane tab="서브배너" key="2">
          <BannerContent data={data} columns={columns} />
        </TabPane>
        <TabPane tab="메인팝업" key="3">
          <BannerContent data={data} columns={columns} />
        </TabPane>
      </Tabs>
    </BannerListWrap>
  );
};

const BannerContent = (props) => {
  //const { data, columns } = props;
  return (
    <div>
    </div>
  );
};

export default BannerList;
