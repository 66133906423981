import { DatePicker } from "antd";
import moment from "moment";
import { dateFormat } from "../../lib/consts";

const { RangePicker } = DatePicker;


const CustomDatePicker = (props) => {
  const { type, onChange = () => {}, date = new Date() } = props;
  return type === "range" ? (
    <>
      <RangePicker
        onChange={onChange}
        defaultValue={[
          moment(date["from"], dateFormat),
          moment(date["to"], dateFormat),
        ]}
        value={[
          moment(date["from"], dateFormat),
          moment(date["to"], dateFormat),
        ]}
      />
    </>
  ) : (
    <DatePicker />
  );
};

export default CustomDatePicker;
