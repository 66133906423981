import axios from "axios";
import { STORAGE_KEY } from "../../lib/consts";

const LOGIN = "user/LOGIN";
const LOGOUT = "user/LOGOUT";

const initState = { isLogin: false, userIp: "", loginId: "" };

export default function user(state = initState, action) {
  switch (action.type) {
    case "user/LOGIN":
      return setLoginStatus(action.payload);
    case "user/LOGOUT":
      localStorage.removeItem(STORAGE_KEY);
      return setLogoutStatus();
    default:
      const isCheck = localStorage.getItem(STORAGE_KEY)
      const checkData = JSON.parse(localStorage.getItem(STORAGE_KEY));
      const data = isCheck ? setLoginStatus(checkData) : initState;
       return {...data};
  }
}

export function onLogin(payload = {}) {
  return { type: LOGIN, payload: payload };
}

export function onLogout() {
  return { type: LOGOUT };
}


const setLogoutStatus = () => {
  localStorage.removeItem(STORAGE_KEY);
  delete axios.defaults.headers.common["Authorization"];
  return {...initState};
}

const setLoginStatus = (payload) => {
  const localData = localStorage.getItem(STORAGE_KEY) ? JSON.parse(localStorage.getItem(STORAGE_KEY)) : payload;
  const { loginId, userIp, authToken } = localData;

  localStorage.setItem(STORAGE_KEY, JSON.stringify(localData));
  axios.defaults.headers.common["Authorization"]  = `Bearer ${authToken}`;

  return {
    isLogin: true,
    userIp: userIp,
    loginId,
  };
};
