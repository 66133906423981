import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MemberLoginWrap } from "./index";
import { Redirect } from "react-router-dom";
import mainLogo from "../../../aseets/img/logo.png";
import { Input, Button } from "antd";
import { KeyOutlined, UserSwitchOutlined } from "@ant-design/icons";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import * as APIS from "../../../lib/api/index";
import { onOpen } from "../../../modules/reducers/modal";
import { onLogin } from "../../../modules/reducers/user";

const Login = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    document.getElementById("id").focus();
  }, []);

  const [loginInfo, setLoginInfo] = useState({
    id: "",
    password: "",
    otp: "",
  });

  // 로그인
  const handleLogin = () => {
    if (!loginInfo.id || !loginInfo.password) {
      alert("아이디와 비밀번호를 입력해주세요.");
      return;
    }
    APIS.postLogin({
      loginId: loginInfo.id,
      password: loginInfo.password,
      otp: loginInfo.otp,
    })
      .then((response) => {
        const { data, success, message } = response.data;
        if (success) {
          const { authToken, ip } = data;
          const payload = {
            loginId: loginInfo.id,
            authToken: authToken,
            userIp: `${ip}`,
          };
          dispatch(onLogin(payload));
        } else {
          alert(message);
        }
      })
      .catch((e) => {
        dispatch(
          onOpen({
            message:
              "서버 통신에 문제가 발생했습니다. 잠시 후 다시 시도해주세요.",
          })
        );
      });
  };

  const onChangeValue = ({ target }) => {
    const { name, value } = target;
    setLoginInfo({ ...loginInfo, [name]: value });
  };

  return (
    <>
      {user.isLogin ? (
        <Redirect to="/quote/wholeSale-quote-management" />
      ) : (
        <MemberLoginWrap>
          <div className="login-wrap">
            <div className="login-logo">
              <img src={mainLogo} alt="default" width="250px" height="64px" />
            </div>
            <div className="login-info">
              <div>
                <label>
                  <UserSwitchOutlined />
                  &nbsp;아이디
                </label>
                <Input
                  type="text"
                  id="id"
                  name="id"
                  value={loginInfo.id}
                  onChange={onChangeValue}
                />
              </div>
              <br />
              <div>
                <label>
                  <KeyOutlined />
                  &nbsp;비밀번호
                </label>
                <Input.Password
                  name="password"
                  value={loginInfo.password}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  onChange={onChangeValue}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleLogin();
                    }
                  }}
                />
              </div>
              <br />
              {/* <div>
                <label>
                  <UserSwitchOutlined />
                  &nbsp;OTP
                </label>
                <Input
                  type="text"
                  name="otp"
                  value={loginInfo.otp}
                  onChange={onChangeValue}
                />
              </div> */}
              <div className="bottom-button">
                <Button style={{ width: "262px" }} onClick={handleLogin}>
                  로그인
                </Button>
              </div>
            </div>
          </div>
        </MemberLoginWrap>
      )}
    </>
  );
};

export default Login;
