import React from "react";
import { Select } from "antd";

const DropDownList = (props) => {
  const { data, onChange } = props;
  const { Option } = Select;
  return (
    <Select
      style={{ width: 200, textAlign: "center" }}
      placeholder="검색조건을 선택해주세요."
      onChange={onChange}
    >
      {(data || []).map((item) => {
       return <Option key={item.key}>{item.value}</Option>;
      })}
    </Select>
  );
};

export default DropDownList;
