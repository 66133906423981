const ONLOAD = "load/ONLOAD";
const OFFLOAD = "load/OFFLOAD";

const initState = {
  isLoading: false,
};

export default function loading(state = initState, action = {}) {
  switch (action.type) {
    case "load/ONLOAD":
      return { isLoading: true };
    case "load/OFFLOAD":
      return { isLoading: false };
    default:
      return state;
  }
}

export function onLoad() {
  return { type: ONLOAD };
}

export function offLoad() {
  return { type: OFFLOAD };
}
