import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Switch, Table, Input, Checkbox } from "antd";
import DataTable, { DragHandle } from "../../../components/common/DataTable";
import { DiamondListWrap } from "./index";
import { cloneDeep } from "lodash";
import * as APIS from "../../../lib/api/index";
import { numberWithCommas, unComma } from "../../../lib/utils";
import { onOpen } from "../../../modules/reducers/modal";
import { MinusCircleOutlined } from "@ant-design/icons";
import useAsync from "../../../lib/hooks/useAsync";

const INIT = {
  id: "",
  company: "",
  weight: "",
  color: "",
  clarity: "",
  cutRate: "",
  price: 0,
  isSoldOut: "",
  status: "",
  etc: false,
};

const { Column } = Table;

const DiamondList = () => {
  const [isModify, setModify] = useState(false);
  const [selected, setSelected] = useState([]);
  const [drag, setDrag] = useState(false);
  const dispatch = useDispatch();

  const initAction = () => {
    setSelected([]);
    setModify(false);
  };

  const [state, refetch] = useAsync(APIS.getDiamondList, [], true, initAction);
  const { data } = state;
  const [diamondList, setDiamondList] = useState([]);
  const [tempList, setTempList] = useState([]);

  // 데이터 호출시 저장
  useEffect(() => {
    setDiamondList(data?.["diamondPriceDtoList"] || []);
    setTempList(data?.["diamondPriceDtoList"] || []);
  }, [data]);

  // 추가된 항목 저장
  const onClickAddSave = (index) => {
    let saveData = cloneDeep(diamondList[index]);
    const check = checkVaildation(saveData);
    delete saveData["etc"];
    if (!saveData['price'] instanceof Number  && saveData["price"].includes(",")) {
      saveData["price"] = unComma(saveData["price"]);
    }
    const modal = {
      title: "알림",
      type: "confirm",
      message: "저장하시겠습니까?",
      action: () =>
        refetch(
          () => APIS.postAddDiamondItem(saveData),
          `저장이 완료되었습니다.`,
          true
        ),
    };
    if (check) {
      dispatch(onOpen(modal));
    } else {
      dispatch(onOpen({ message: "입력하지 않은 항목이 있습니다." }));
    }
  };

  // 기존 항목 건별 저장
  const onClickSave = async (index) => {
    let data = cloneDeep(diamondList[index]);
    delete data["etc"];
    if (data['price'] instanceof Number === false && data["price"].includes(",")) {
      data["price"] = unComma(data["price"]);
    }

    const modal = {
      title: "알림",
      type: "confirm",
      message: "저장하시겠습니까?",
      action: () =>
        refetch(
          () => APIS.patchModifyDiamondItem(data),
          `저장이 완료되었습니다..`,
          true
        ),
    };

    dispatch(onOpen(modal));
  };

  // 항목 순서변경
  const onChangePosition = () => {
    const parseData = cloneDeep(diamondList)
      .filter((item) => item.id)
      .map((item, index) => {
        return { id: item.id, position: index };
      });
    const modal = {
      title: "알림",
      type: "confirm",
      message: "순서를 변경하시겠습니까?",
      action: () =>
        refetch(
          () => APIS.patchChangePositionDiamondList(parseData),
          `변경이 완료되었습니다.`,
          true
        ),
    };

    dispatch(onOpen(modal));
  };

  // 항목 삭제
  const onClickDelete = () => {
    let modal = {};
    if (selected.length === 0) {
      modal = {
        title: "알림",
        type: "alert",
        message: "삭제할 항목을 1개이상 선택해주세요.",
      };
      dispatch(onOpen(modal));
      return false;
    } else if (selected.length > 0) {
      const data = {
        ids: cloneDeep(selected),
      };
      modal = {
        title: "알림",
        type: "confirm",
        message: "선택하신 항목을 삭제하시겠습니까?",
        action: () =>
          refetch(
            () => APIS.deleteDiamondItem(data),
            `삭제가 완료되었습니다.`,
            true
          ),
      };
      dispatch(onOpen(modal));
    }
  };

  const onClickAdd = () => {
    const copyDiamondList = cloneDeep(diamondList);
    const parseDiamondList = copyDiamondList.concat(cloneDeep(INIT));
    setDiamondList(parseDiamondList);
  };

  const onChangeSwitch = (checked, key, index) => {
    let copyData = cloneDeep(diamondList);
    copyData[index] = {
      ...copyData[index],
      [key]: checked,
    };
    setDiamondList(copyData);
  };

  const onClickSelected = (e, value) => {
    const { checked } = e.target;

    if (checked) {
      setSelected([...selected, value]);
    } else {
      setSelected(selected.filter((item) => item !== value));
    }
  };

  const checkVaildation = (saveData) => {
    let check = true;
    if (!saveData.company) {
      check = false;
    }
    if (!saveData.weight) {
      check = false;
    }
    if (!saveData.color) {
      check = false;
    }
    if (!saveData.clarity) {
      check = false;
    }
    if (!saveData.cutRate) {
      check = false;
    }
    if (!saveData.price) {
      check = false;
    }
    return check;
  };

  const handleChangeModify = (e) => {
    setModify(!isModify);
  };

  const onClickCancel = () => {
    initAction();
    setDiamondList(cloneDeep(tempList));
  };

  // var _pattern2 = /^\d*[.]\d{2}$/

  const onChangeValue = (e) => {
    const { name, value, dataset } = e.target;
    let copyData = cloneDeep(diamondList);
    let parseValue = value;

    if (name === "weight") {
      const _pattern2 = /^\d*[.]\d{5}$/;
      if (_pattern2.test(parseValue)) {
        return false;
      }
    }
    copyData[dataset["index"]] = {
      ...copyData[dataset["index"]],
      [name]: parseValue,
    };

    setDiamondList(copyData);
  };

  const onClickItemRemove = (index) => {
    const copyList = cloneDeep(diamondList);
    copyList.splice(index, 1);
    setDiamondList(copyList);
  };

  const EditCell = (props) => {
    const { value, index, key } = props;
    const type = key === "price" ? true :false 

    return (
      <span className="center">
        {isModify ? (
          <Input
            name={key}
            data-index={index}
            value={type ?numberWithCommas(value) : value}
            onChange={onChangeValue}
          />
        ) : (
          value
        )}
      </span>
    );
  };

  return (
    <DiamondListWrap>
      <div className="main-title-wrap" id="dia-list">
        <span className="main-title">시세/매입가 관리</span>
      </div>
      <div className="sub-title">
        <span>시세/매입가 관리 &gt; 다이아몬드 시세관리</span>
      </div>
      <div className="button-wrap">
        {isModify ? (
          <>
            <Button onClick={onClickAdd}>추가</Button>&nbsp;
            <Button onClick={onClickDelete}>삭제</Button>&nbsp;
            <Button onClick={onChangePosition}>순서 변경</Button>
            &nbsp;&nbsp;&nbsp;
            <Button onClick={onClickCancel}>취소</Button>
          </>
        ) : (
          <Button onClick={handleChangeModify}>수정</Button>
        )}
      </div>
      <DataTable
        name="diamondList"
        data={diamondList}
        dragable={true}
        drag={drag}
        height={800}
        setDrag={(value) => setDrag(value)}
        setData={setDiamondList}
      >
        {isModify && (
          <Column
            title="Sort"
            dataIndex="sort"
            className="drag-visible"
            width={100}
            render={(value, _, index) => {
              return diamondList[index]["id"] ? (
                <span className="center"><DragHandle onClick={() => setDrag(true)}/></span>
              ) : (
                <span style={{ fontSize: "8px" }}>New</span>
              );
            }}
          />
        )}
        <Column
          title="No"
          key="id"
          dataIndex="id"
          className="center"
          width={100}
          render={(value, _, index) => {
            return value ? (
              <span style={{ width: "72px" }}>
                <Checkbox
                  checked={selected.includes(value)}
                  disabled={!isModify}
                  onChange={(e) => onClickSelected(e, value)}
                />
              </span>
            ) : (
              <span onClick={() => onClickItemRemove(index)} className="center">
                <MinusCircleOutlined />
              </span>
            );
          }}
        />
        <Column
          title="감정"
          key="company"
          dataIndex="company"
          className="center"
          width={300}
          render={(value, _, index) =>
            EditCell({ value: value, index: index, key: "company" })
          }
        />
        <Column
          title="중량(ct)"
          key="weight"
          dataIndex="weight"
          className="center"
          render={(value, _, index) =>
            EditCell({ value: value, index: index, key: "weight" })
          }
        />
        <Column
          title="컬러"
          key="color"
          dataIndex="color"
          className="center"
          render={(value, _, index) =>
            EditCell({ value: value, index: index, key: "color" })
          }
        />
        <Column
          title="투명도"
          key="clarity"
          dataIndex="clarity"
          className="center"
          render={(value, _, index) =>
            EditCell({ value: value, index: index, key: "clarity" })
          }
        />
        <Column
          title="컷등급"
          key="cutRate"
          dataIndex="cutRate"
          className="right"
          render={(value, _, index) =>
            EditCell({ value: value, index: index, key: "cutRate" })
          }
        />
        <Column
          title="할인판매가"
          key="price"
          dataIndex="price"
          className="right"
          render={(value, _, index) => {
            return isModify ? (
              EditCell({ value: value, index: index, key: "price" })
            ) : (
              <span>{numberWithCommas(value)}</span>
            );
          }}
        />
        <Column
          title="품절"
          key="isSoldOut"
          dataIndex="isSoldOut"
          className="center"
          width={100}
          render={(value, _, index) => {
            return isModify ? (
              <Switch
                checked={value ? true : false}
                disabled={false}
                onChange={(checked) =>
                  onChangeSwitch(checked, "isSoldOut", index)
                }
              />
            ) : (
              <Switch checked={value ? true : false} disabled={true} />
            );
          }}
        />
        <Column
          title="활성화"
          key="status"
          dataIndex="status"
          className="center"
          width={100}
          render={(value, _, index) => {
            return isModify ? (
              <Switch
                checked={value ? true : false}
                disabled={false}
                onChange={(checked) => onChangeSwitch(checked, "status", index)}
              />
            ) : (
              <Switch checked={value ? true : false} disabled={true} />
            );
          }}
        />
        {isModify && (
          <Column
            title="비고"
            key="etc"
            dataIndex="etc"
            width={100}
            render={(value, _, index) => {
              return (
                <Button
                  data-index={index}
                  data-value={value}
                  onClick={
                    diamondList[index]["id"]
                      ? () => onClickSave(index)
                      : () => onClickAddSave(index)
                  }
                >
                  저장
                </Button>
              );
            }}
          />
        )}
      </DataTable>
    </DiamondListWrap>
  );
};

export default DiamondList;
