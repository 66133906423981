import React, { useEffect, useState } from "react";
import { SecurityListWrap } from "./index";
import { Input, Button, Descriptions } from "antd";
import * as APIS from "../../../lib/api/index";
import useAsync from "../../../lib/hooks/useAsync";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import { numberWithCommas } from "../../../lib/utils";
import { onOpen } from "../../../modules/reducers/modal";

const SecurityList = () => {
  const [productData, setProductData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [currentPrice, setCurrentPirceList] = useState([]);
  const [isModify, setModify] = useState(false);
  const [state, refetch] = useAsync(APIS.getSecurityList, [], true, () =>
    setModify(false)
  );
  const dispatch = useDispatch();
  const { data } = state;
  useEffect(() => {
    setProductData(
      data?.["securityDtoList"] || []
    );
    setTempData(data?.["securityDtoList"] || []);
    setCurrentPirceList(data?.["domesticMarketPriceDtoList"] || []);
  }, [data]);

  const onClickModify = () => {
    setModify(!isModify);
  };

  const onClickCancel = () => {
    setProductData(tempData);
    setModify(false);
  };

  const onChangeValue = (e, index) => {
    const { name, value } = e.target;

    let copyData = cloneDeep(productData);
    let data = cloneDeep(productData[index]);
    let parseValue = value;

    if (name === "weight" || name === "premium") {
      parseValue = value.replace(/[^0-9]/g, "");
    }

    data = {
      ...data,
      [name]: parseValue,
    };

    copyData[index] = data;
    const parseData = copyData;

    setProductData(parseData);
  };

  const onClickSave = (index) => {
    const saveData = {
      ...productData[index],
      weight: +productData[index]["weight"],
      premium: +(productData[index]["premium"] / 100).toFixed(3),
    };

    const modal = {
      title: "알림",
      type: "confirm",
      message: "유가증권 목록을 수정하시겠습니까?",
      action: () => {
        refetch(
          () => APIS.patchModifySecurityList(saveData),
          `수정이 완료되었습니다.`,
          true
        );
      },
    };

    dispatch(onOpen(modal));
  };

  return (
    <SecurityListWrap>
      <div className="main-title-wrap" id="dia-list">
        <span className="main-title">시세/매입가 관리</span>
      </div>
      <div className="sub-title">
        <span>시세/매입가 관리 &gt; 유가증권관리</span>
      </div>
      <div className="security-wrap">
        <div className="security-info">
          <div className="button-wrap">
            {isModify ? (
              <>
                <Button onClick={onClickCancel}>취소</Button>
              </>
            ) : (
              <Button onClick={onClickModify}>수정</Button>
            )}
          </div>
          <span>유가증권</span>
          <Descriptions
            bordered
            column={{
              xxl: 4,
              xl: 3,
              lg: 3,
              md: 3,
              sm: 2,
              xs: 1,
            }}
          >
            {(productData || []).map((item, index) => {
              return (
                <React.Fragment key={`securityList${index}`}>
                  <Descriptions.Item label="제품">
                    <span>{item.description}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label="프리미엄률(%)">
                    {isModify ? (
                      <Input
                        value={item.premium}
                        name="premium"
                        onChange={(e) => onChangeValue(e, index)}
                        maxLength={3}
                      />
                    ) : (
                      <span>{item.premium}</span>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="중량(g)">
                    {isModify ? (
                      <Input
                        value={numberWithCommas(item.weight)}
                        name="weight"
                        onChange={(e) => onChangeValue(e, index)}
                      />
                    ) : (
                      <span>{numberWithCommas(item.weight)}</span>
                    )}
                  </Descriptions.Item>
                  {!isModify && (
                    <Descriptions.Item label="가격">
                      <span>₩ {numberWithCommas(item.price)}</span>
                    </Descriptions.Item>
                  )}
                  {isModify && (
                    <Descriptions.Item className="center">
                      <Button onClick={() => onClickSave(index)}>저장</Button>
                    </Descriptions.Item>
                  )}
                </React.Fragment>
              );
            })}
          </Descriptions>
          <br />
          <span>현재 시세</span>
          <Descriptions bordered>
            {(currentPrice || []).map((item, index) => {
              return (
                <React.Fragment key={`currentPrice${index}`}>
                  <Descriptions.Item label="자산">
                    {item?.description || "-"} / {item?.type || "-"}
                  </Descriptions.Item>
                  <Descriptions.Item label="기준가">
                    ₩ {numberWithCommas(item.price)}
                  </Descriptions.Item>
                  <Descriptions.Item label="날짜">
                    {item.regdate}
                  </Descriptions.Item>
                </React.Fragment>
              );
            })}
          </Descriptions>
        </div>
      </div>
    </SecurityListWrap>
  );
};

export default SecurityList;
