import React from "react";
import { useDispatch } from "react-redux";
import { Table } from "antd";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import { arrayMoveImmutable } from "array-move";
import { onOpen } from "../../modules/reducers/modal";
import { numberWithCommas } from "../../lib/utils";
import { useSelector } from "react-redux";
import { setPage } from "../../modules/reducers/page";
import { useHistory } from "react-router-dom";

const DataTable = (props) => {
  const {
    data = [],
    name,
    pagination = false,
    rowSelection = false,
    children,
    dragable = false,
    setData,
    drag = false,
    setDrag,
    detail = "",
    height = 400,
  } = props;

  const pageData = useSelector((state) => state.page);
  const history = useHistory();

  const alignChildren = (children.length > 0 ? children : [])
    .filter((data) => data !== false)
    .map((item) => {
      return { ...item, props: { ...item.props, align: "center" } };
    });

  const dispatch = useDispatch();

  const SortableItem = sortableElement((props) => {
    return <tr {...props} />;
  });
  const SortableContainer = sortableContainer((props) => {
    const el = React.cloneElement(<tbody {...props} />);
    return el;
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      if (data[newIndex]["id"]) {
        const newData = arrayMoveImmutable(
          [].concat(data),
          oldIndex,
          newIndex
        ).filter((el) => !!el);
        setData(newData);
        setDrag(false);
      } else {
        dispatch(
          onOpen({
            message:
              "새로 추가된 아이템입니다.\n 추가 후 순서 변경이 가능합니다.",
          })
        );
        setDrag(false);
        return false;
      }
    }
    setDrag(false);
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = data.findIndex(
      (x, index) => index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  let defaultProps = {};
  defaultProps = {
    position: pagination ? ["none", "bottomCenter"] : ["none", "none"],
  };
  if (pagination) {
    defaultProps = {
      ...defaultProps,
      pageSizeOptions: ["30", "50", "100"],
      showSizeChanger: true,
      total: pageData.totalElements,
      defaultCurrent: pageData.number + 1,
      onChange: (page, size) => {
        document.getElementById("totalElement").scrollTo(0, 0);
        dispatch(setPage({ ...pageData, size: size, number: page - 1 }));
      },
      pageSize: pageData.size,
      showTotal: () => {
        return (
          <span
            style={{ position: "absolute", right: "2px", top: "-30px" }}
            id="totalElement"
          >
            조회된 게시글 수 : {pageData.totalElements}
          </span>
        );
      },
    };
  } else {
    defaultProps = {
      pageSize: 50,
      position: ["none", "none"],
    };
  }

  return (
    <>
      <Table
        key={name}
        pagination={defaultProps}
        rowKey={(_, index) => {
          return index;
        }}
        onRow={
          rowSelection
            ? (props) => ({
                onClick: () => {
                  history.push(detail, { id: props.id });
                },
              })
            : () => {}
        }
        dataSource={data}
        components={
          dragable &&
          drag && {
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }
        }
        size="small"
        bordered
        height={{ minHeight: height, maxHeight: height }}
        scroll={{ y: height - 100 }}
      >
        {alignChildren}
      </Table>
    </>
  );
};

export default DataTable;

export const DragHandle = sortableHandle((props) => {
  return (
    <MenuOutlined
      style={{ cursor: "grab", color: "#999", fontSize: "20px" }}
      onClick={() => props.onClick()}
    />
  );
});

export const numberWithCommasCell = (props) => {
  const value = props || "-";
  return <span className="right">{numberWithCommas(value)}</span>;
};
