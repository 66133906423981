import React from "react";
import { CustomerDetailWrap } from "./index";
import { Button, Input } from "antd";

const CustomerDetail = () => {
  return (
    <CustomerDetailWrap>
      <h1>회원 상세정보</h1>
      <hr />
      <div className="customer-info">
        <div className="customer-info-form">
          <div className="form-wrap">
            <div>
              <label>이름</label>
              <Input type="text" style={{ width: 150 }} />
            </div>
          </div>
          <div className="form-wrap">
            <div>
              <label>비밀번호</label>
              <Button>비밀번호 초기화</Button>
            </div>
          </div>
          <div className="form-wrap" style={{ display: "flex" }}>
            <div>
              <label>상호명{""}{""}{""}</label>
              <Input type="text" style={{ width: 150 }} />
            </div>
            <div>
              <label>사업자번호</label>
              <Input type="text" style={{ width: 150 }} />
            </div>
          </div>
          <div className="form-wrap">
            <div>
              <label>이메일</label>
              <Input type="text" style={{ width: 150 }} />
            </div>
          </div>
          <div className="form-wrap" style={{ display: "flex" }}>
            <div>
              <label>휴대폰 번호</label>
              <Input type="text" style={{ width: 150 }} />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Input type="checkbox" style={{ width: 30 }} />
              <label>정보/이벤트 SMS수신동의</label>
            </div>
          </div>
          <div className="form-wrap" style={{ display: "flex" }}>
            <div>
              <label>전화번호</label>
              <Input type="text" style={{ width: 150 }} />
            </div>
            <div>
              <label>팩스번호</label>
              <Input type="text" style={{ width: 150 }} />
            </div>
          </div>
          <div className="form-wrap">
            <div>
              <label>주소</label>
              <Input type="text" style={{ width: 150 }} />
            </div>
          </div>
          <div className="form-wrap">
            <div>
              <label>가입일</label>
              <Input type="text" style={{ width: 150 }} />
            </div>
          </div>
          <div className="bottom-button">
            <Button>저장하기</Button>
            <Button>탈퇴처리</Button>
            <Button>돌아가기</Button>
          </div>
        </div>
      </div>
    </CustomerDetailWrap>
  );
};

export default CustomerDetail;
