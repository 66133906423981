const OPEN = "modal/OPEN";
const CLOSE = "modal/CLOSE";
const CONFIRM = "modal/CONFIRM";

const initState = {
  status: false,
  value: false,
};

export default function modal(state = initState, action = {}) {
  switch (action.type) {
    case "modal/OPEN":
      return { ...action.payload, status: true, value: false };
    case "modal/CLOSE":
      return { status: false, value: false };
    case "modal/CONFIRM":
      return { status: false, value: action.payload };
    default:
      return state;
  }
}

export function onOpen(payload) {
  return { type: OPEN, payload: payload };
}

export function onClose() {
  return { type: CLOSE };
}

export function onConfirm(payload) {
  return { type: CONFIRM, payload: payload };
}
